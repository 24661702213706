import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

class IndexPage extends React.Component {
  render() {
    const siteTitle = "Collin Colaizzi's Website"

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Home"
          keywords={[`blog`, `gatsby`, `javascript`, `react`]}
        />
        <Image />
        <h1>Welcome. </h1>
        <p>Grab yourself a plate.</p>
        <Link to="/blog/">Go to Blog</Link>
      </Layout>
    )
  }
}

export default IndexPage
